<template>
  <div class="p-3">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-9 col-12 h-100">
        <appProgressSteps :propsData="stepId" :shId="shId"></appProgressSteps>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-12 h-100">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="../../assets/img/cv-screen-marketing-1.jpeg"
                  style="height: 77px"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-md-9 col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <h5>Review & Submit</h5>
                <div class="underline"></div>
              </div>
            </div>

            <appReviewBlock1 :propsData="getPoliceCheckSingle">
            </appReviewBlock1>
            <appReviewBlock2
              v-if="
                this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2
              "
              :propsData="getPoliceCheckSingle"
            >
            </appReviewBlock2>

            <div
              class="col-xl-12 col-lg-12 col-md-12 col-12 mt-3"
              v-if="
                this.loginUserObj.role_id == 3 ||
                this.loginUserObj.role_id == 4 ||
                this.loginUserObj.role_id == 9
              "
            >
              <h5 class="text-info">Consent</h5>
              <dl class="row">
                <dt class="col-sm-2">General</dt>
                <dd class="col-sm-10">
                  <p>
                    Our information services are provided in accordance with our
                    standard Terms and Conditions accessible at
                    <a
                      href="//cvscreen.com.au/terms-conditions/"
                      target="_blank"
                      >www.cvscreen.com.au/terms-conditions/</a
                    >.
                  </p>
                </dd>

                <dt class="col-sm-2">I am over 18 years of age</dt>
                <dd class="col-sm-10">
                  <div>
                    <b-form-group
                      id="radio-group-1"
                      label="If you are less than 18 years of age, the National Police Check form must be co-signed by your parent or legal guardian"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio
                        v-model="yearsOfAge"
                        :aria-describedby="ariaDescribedby"
                        name="years_of_age"
                        value="O"
                        >I am over 18 years of age</b-form-radio
                      >

                      <b-form-radio
                        v-model="yearsOfAge"
                        :aria-describedby="ariaDescribedby"
                        name="years_of_age"
                        value="U"
                        >I am under 18 years of age</b-form-radio
                      >
                    </b-form-group>
                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.yearsOfAge"
                    >
                      Age consent is required
                    </div>
                  </div>
                </dd>

                <dt class="col-sm-2">National Police Check</dt>
                <dd
                  class="col-sm-10"
                  v-if="
                    getPoliceCheckSingle && getPoliceCheckSingle.profile_info
                  "
                >
                  I, {{ getPoliceCheckSingle.profile_info.first_name }}
                  {{ getPoliceCheckSingle.profile_info.last_name }}, herby
                  consent to CV Screen Pty Ltd using my personal details to
                  conduct or procure the conduct of a National Police Check.
                </dd>
                <dt class="col-sm-2">Signature</dt>
                <dd class="col-sm-10">
                  <div class="invalid-feedback" v-if="errors.signature">
                    {{ errors.signature[0] }}
                  </div>
                  <VueSignaturePad
                    width="100%"
                    height="200px"
                    ref="signaturePad"
                    class="border border-secondary"
                  />
                  <div class="mt-2">
                    <button
                      class="btn btn-info btn-sm"
                      @click="fnSignaturePadUndo"
                    >
                      Undo
                    </button>
                  </div>
                </dd>
                <dt class="col-sm-2"></dt>
                <dd class="col-sm-10 mt-3">
                  <wdImage
                    v-if="getPoliceCheckSingle.signature"
                    :imgUrl="getPoliceCheckSingle.signature"
                    :imgStyle="'height: 100px; width: auto'"
                    :imgClass="'card-img-top'"
                  ></wdImage>
                </dd>

                <dt class="col-sm-2" v-if="yearsOfAge == 'U'">
                  Parent or Legal Guardian Signature
                </dt>
                <dd class="col-sm-10" v-if="yearsOfAge == 'U'">
                  <div class="invalid-feedback" v-if="errors.guardianSignature">
                    {{ errors.guardianSignature[0] }}
                  </div>
                  <VueSignaturePad
                    width="100%"
                    height="200px"
                    ref="guardianSignaturePad"
                    class="border border-secondary"
                  />
                  <div class="mt-2">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="fnGuardianSignaturePadUndo"
                    >
                      Undo
                    </button>
                  </div>
                </dd>
                <dt class="col-sm-2" v-if="yearsOfAge == 'U'"></dt>
                <dd class="col-sm-10 mt-3" v-if="yearsOfAge == 'U'">
                  <wdImage
                    v-if="getPoliceCheckSingle.guardianSignature"
                    :imgUrl="getPoliceCheckSingle.guardianSignature"
                    :imgStyle="'height: 100px; width: auto'"
                    :imgClass="'card-img-top'"
                  ></wdImage>
                </dd>
              </dl>
            </div>

            <!-- Partner and Partner Staff consent -->
            <div
              class="row"
              v-if="
                this.loginUserObj.role_id == 6 || this.loginUserObj.role_id == 7
              "
            >
              <div class="col-12 col-lg-12 col-md-12 mt-5">
                <h5 class="text-info">Consent</h5>
                <p>
                  Our information services are provided in accordance with our
                  standard Terms and Conditions accessible at
                  <a
                    href="https://cvscreen.com.au/terms-conditions/"
                    target="_blank"
                    >here</a
                  >.
                </p>
                <p class="">
                  Please upload the completed applicant consent form.
                </p>
              </div>
              <div class="col-12 col-lg-6 col-md-6">
                <div
                  class="alert alert-danger mb-3 col-12"
                  v-if="msg.length > 0"
                >
                  <p
                    class="fs-800 mb-0"
                    v-for="(item, index) in msg"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>

                <appFileUploadComSingle
                  :policeCheckId="id"
                  :doc_urls="propsData.consent_partner"
                  :documentTypeId="81"
                  :documentType="'Consent partner'"
                ></appFileUploadComSingle>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right pt2000">
                <button
                  class="btn-secondary btn-sm mr-2 btn-w-100"
                  @click="fnPreviousStep()"
                >
                  Previous
                </button>
                <button
                  v-if="
                    this.loginUserObj.role_id == 3 ||
                    this.loginUserObj.role_id == 9
                  "
                  class="btn-info btn-sm btn-w-100"
                  @click="fnSignatureSave()"
                >
                  <span
                    ><b-spinner small v-if="loading" class="mr-2"></b-spinner
                    >Submit</span
                  >
                </button>

                <!-- Partner submit -->
                <button
                  v-if="
                    this.loginUserObj.role_id == 6 ||
                    this.loginUserObj.role_id == 7
                  "
                  class="btn-info btn-sm btn-w-100"
                  @click="fnPartnerSubmit()"
                >
                  <span
                    ><b-spinner small v-if="loading" class="mr-2"></b-spinner
                    >Submit</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="card" v-if="loginUserObj.role_id != 1">
          <div class="card-body h-overflow">
            <appStep6Ins></appStep6Ins>
          </div>
        </div>
        <div
          v-if="
            this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2
          "
        >
          <appCheckList1
            :policeCheckId="id"
            :propsData="propsData"
          ></appCheckList1>
        </div>
        <div
          v-if="
            this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2
          "
        >
          <appCheckListNotes
            v-if="notesData"
            :propsData="notesData"
            :policeCheckId="id"
          ></appCheckListNotes>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import appStep6Ins from "../../components/policeCheck/step6Ins.vue";
import appProgressSteps from "../../components/policeCheck/progressSteps.vue";
import * as policeCheckService from "../../services/policeCheck.service";
import appReviewBlock1 from "../../components/policeCheck/reviewBlock1.vue";
import appReviewBlock2 from "../../components/policeCheck/reviewBlock2.vue";
import appCheckList1 from "../../components/policeCheck/pcCheckListCom.vue";
import appCheckListNotes from "../../components/notes/CheckListNotesCom.vue";
import appFileUploadComSingle from "../../components/common/FileUploadComSingle.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import wdImage from "../../components/wd/WDImage.vue";

export default {
  name: "CheckPoliceStep6",
  components: {
    appStep6Ins,
    appReviewBlock1,
    appReviewBlock2,
    appCheckList1,
    appCheckListNotes,
    appFileUploadComSingle,
    appProgressSteps,
    wdImage,
  },
  /**
   * sh (stake holder)
   * 1 Individual
   * 2 Partner
   * 3 Corporate
   */
  data() {
    return {
      id: encodeDecodeService.cyDecrypt(this.$route.params.id),
      shId: encodeDecodeService.cyDecrypt(this.$route.params.shId), // Stake Holder id it would be 1,2,3
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      parentId: encodeDecodeService.cyDecrypt(this.$route.params.parentId),
      customerId: encodeDecodeService.cyDecrypt(this.$route.params.customerId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId), // Product id
      mode: encodeDecodeService.cyDecrypt(this.$route.params.mode),
      stepId: 6,

      loading: false,
      errors: {},
      yearsOfAge: "",
      loginUserObj: {},
      notesData: [],
      propsData: {},
      msg: [],
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),
    fnPreviousStep() {
      // Individual Customer
      if (this.getPoliceCheckSingle.sh_id == 1) {
        this.$router.push({
          name: "checkPoliceStep5IU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(this.projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(this.checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      }

      // Corporate customer
      if (
        this.getPoliceCheckSingle.sh_id == 2 ||
        this.getPoliceCheckSingle.sh_id == 3
      ) {
        this.$router.push({
          name: "checkPoliceStep5CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(this.projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(this.checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      }
    },
    fnSignaturePadUndo() {
      this.$refs.signaturePad.undoSignature();
    },
    fnGuardianSignaturePadUndo() {
      this.$refs.guardianSignaturePad.undoSignature();
    },

    fnSignaturePadSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      return data;
    },
    fnGuardianSignaturePadSave() {
      const { isEmpty, data } = this.$refs.guardianSignaturePad.saveSignature();
      return data;
    },
    // Individual user
    fnSignatureSave: async function () {
      this.loading = true;

      let guardianSignatureData = null;
      let signatureData = null;

      signatureData = this.fnSignaturePadSave();

      if (this.yearsOfAge === "U") {
        guardianSignatureData = this.fnGuardianSignaturePadSave();
      }

      let obj = {
        yearsOfAge: this.yearsOfAge,
        signature: signatureData,
        guardianSignature: guardianSignatureData,
        status: 2,
      };

      try {
        let res = "";

        res = await policeCheckService.signatureUpload(this.id, obj);

        this.errors = {};

        messageService.fnToastSuccess("Application updated successfully!");
        if (res.status === 200) {
          // Corporate customer
          if (
            this.getPoliceCheckSingle.sh_id == 2 ||
            this.getPoliceCheckSingle.sh_id == 3
          ) {
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.checkId),
              },
            });
          }

          // Individual Customer
          if (this.getPoliceCheckSingle.sh_id == 1) {
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgIC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.checkId),
              },
            });
          }

          this.loading = false;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Signature - Something wrong, please try again!",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Signature - new",
              "Something wrong, please try again!"
            );
            break;
        }
        messageService.fnToastError("Something wrong, please try again!");

        this.loading = false;
      }
    },

    // Partner & Partner Staff submit the client's information
    fnPartnerSubmit: async function () {
      this.msg = [];
      if (
        this.propsData.consent_partner == null ||
        this.propsData.consent_partner == "" ||
        this.propsData.consent_partner == "null" ||
        this.propsData.consent_partner == "undefine"
      ) {
        this.msg.push(
          "Consent is required for CV Screen to process a police check. Please upload the applicant's consent."
        );
        return;
      }

      this.loading = true;

      let obj = {
        status: 2,
        is_auto_notification: true,
      };

      try {
        let res = "";

        res = await policeCheckService.updateStatus(this.id, obj);

        this.errors = {};

        messageService.fnToastSuccess("Application updated successfully!");
        if (res.status === 200) {
          this.$router.push({ name: "policeCheckSubmittedMessagePartner" });

          this.loading = false;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Signature - Something wrong, please try again!",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Signature - new",
              "Something wrong, please try again!"
            );
            break;
        }
        messageService.fnToastError("Something wrong, please try again!");

        this.loading = false;
      }
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchPoliceCheckSingle(this.id);
  },
  mounted() {},
  watch: {
    getPoliceCheckSingle: function (val) {
      if (val) {
        this.propsData = val;
        this.yearsOfAge = val.yearsOfAge;
      }
      if (val.notes && val.notes.content) {
        this.notesData = val.notes.content;
      }
    },
  },
};
</script>
